<template>
  <div class="c-table">
    <table class="rwd-table w-full mb-2">
      <tbody>
        <tr>
          <th v-for="column in columns" :key="column.name" class="whitespace-no-wrap text-center">{{ column.label }}</th>
          <slot name="actionsLabel"></slot>
        </tr>
        <tr v-for="(item, index) in itemsWithIndex" :key="index">
          <td :data-th="column.label" v-for="(column, indexColumn) in columns" :key="indexColumn" @click="openModal">
            <span class="d-none d-lg-none">
              {{ item[column.name] }}
            </span>
            <div class="flex-column align-items-center justify-content-center hidden lg:flex d-lg-flex">
              {{ item[column.name] }}
            </div>
          </td>
          <slot name="actionsBtn" :item="item"></slot>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

  export default {
    name: 'Table',
    components: {

    },
    props: {
      columns: {
        name: String,
        required: Boolean,
        label: String,
        sortable: Boolean
      },
      data: Array
    },
    data () {
      return {
        showfirstModal: false
      }
    },
    computed: {
      itemsWithIndex () {
        if (this.data) {
          return this.data.map(
            (items, index) => ({
              ...items,
              index: index + 1
            }))
        }
        return []
      }
    },
    methods: {
      openModal () {
        if (this.$route.name === 'Learn_record_hr') {
          this.$emit('openModal', true)
        }
      },
      // openTeacherInfo (name) {
      //   name === 'teacher' ? this.showfirstModal = true : null
      // },
      closeModal () {
        this.showfirstModal = false
      }
    }
  }
</script>

<style scoped lang="scss">
.cursorPointer {
  cursor: pointer;
}
.rwd-table {
  margin: auto;
  width: 100%;
  min-width: 300px;
  max-width: 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;
  color: #333;
  // overflow: hidden;
  tr {
    text-align: center;
    &:first-child {
      border-top: none;
    }
    th {
      display: none;
      color: white;
      background-color: rgba(90, 93, 155, 1);
      padding: .8em;
    }
  }
  td {
    text-align: center;
    display: block;
    background: white;
    font-weight: 700;
    &:before {
      content: attr(data-th) ": ";
      font-weight: bold;
      width: 120px;
      display: inline-block;
      color: #000;
    }
  }
}

@media screen and (max-width: 1025px) {
  // .rwd-table { border-spacing: 0 0rem !important; }
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
  .rwd-table th,
  .rwd-table td {
    text-align: left;
  }
  .rwd-table th,
  .rwd-table td {
    padding: .5em 1em;
  }

}
@media screen and (min-width: 1024px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell !important;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table td {
    padding: .5em !important;
  }
}
</style>