<template>
  <div class="ChatRate">
    <div class="">
      <h5 class="intro-title text-xl">我的對話評分</h5>
    </div>

    <Table :columns="tableList.columns"
      :data="tableList.datas">
        <template slot="actionsLabel">
          <th class="whitespace-no-wrap text-center">更新時間</th>
          <th class="whitespace-no-wrap text-center">場景主題</th>
          <th class="whitespace-no-wrap text-center">聊天設定</th>
        </template>
        <!-- slot-scope="props" -->
        <template slot="actionsBtn">
          <td data-th="更新時間">
            2024/05/20　20:30:05
          </td>
          <td data-th="場景主題">
            機場>查詢航班
          </td>
          <td data-th="聊天設定">
            女聲 / 英國腔
          </td>
        </template>
    </Table>

    <div class="d-flex text-center list-title mb-3">
      <div class="col-2 p-2">單句分數</div>
      <div class="col-10 p-2">聊天內容</div>
    </div>
    <div class="d-flex">
      <div class="col-2 text-center bg-white d-flex align-items-center justify-content-center">
        <div>
          <p class="font-bold">Score</p>
          <p class="font-bold score">88</p>
        </div>
      </div>
      <div class="col-10 bg-white">
        <div class="d-flex">
          <div class="text-white ai p-2 text-center d-flex align-items-center justify-content-center">AI</div>
          <div class="text-white icon-w p-2 text-center d-flex align-items-center justify-content-center"><img src="@/assets/image/ai/btn_AItalk_play_perple.png"></div>
          <div class="flex-1 d-flex align-items-center">
            <p>Do you like to chat with your friends on the phone?</p>
          </div>
        </div>
        <div class="d-flex">
          <div class="text-white you p-2 text-center d-flex align-items-center justify-content-center">YOU</div>
          <div class="text-white icon-w p-2 text-center d-flex align-items-center justify-content-center"><img src="@/assets/image/ai/btn_AItalk_voice.png"></div>
          <div class="flex-1 d-flex align-items-center">
            <p>Yes, I like to chat with my friends on the phone. We often talk about food and movies.We also like to share about our days.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-center w-100 mt-3">
      <a class="btn-submit mx-auto d-inline-block">繼續對話</a>
    </div>
  </div>
</template>

<script>
  import Table from '@/components/AiHelper/table'
  export default {
    name: 'ChatRate',
    components: {
      Table
    },
    data () {
      return {
        tableList: {
          columns: [
          ],
          datas: [{}]
        }
      }
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
@import "@/assets/styles/color.scss";
.ChatRate {
  padding: 1rem;
  @media (min-width: 640px) {
    padding: 1.5rem;
  }
  .intro-title {
    color: $main-color;
    font-weight: 700;
    margin-bottom: 0.75rem;
    @media (min-width: 640px) {
      margin-right: 0.5em;
    }
  }
  .list-title {
    background: rgba(90, 93, 155, 1);
    color: white;
  }
  .score {
    font-size: 3em;
  }
  .icon-w {
    width: 60px;
  }
  .ai {
    width: 60px;
    background: rgba(90, 93, 155, 1);
  }
  .you {
    width: 60px;
    background: rgba(226, 128, 73, 1);

  }
}

</style>
